import React, { useState, useEffect } from "react";
import NftGif from "../assets/img/gif/about 2.gif";
import PlusImg from "../assets/svg/mintnft/plus.svg";
import MinusImg from "../assets/svg/mintnft/minus.svg";
import LayerImg from "../assets/img/about/aboutlayer1.png";
import LayerImg2 from "../assets/img/about/aboutlayer2.png";

const MintNft = () => {
  const localValue = localStorage.getItem("mine-milk-count");
  const [increaseValue, setIncreaseValue] = useState(0);
  const [value, setValue] = useState(5000);
  const [count, setCount] = useState(parseInt(localValue));
  const counterHandler = () => {
    setIncreaseValue(increaseValue + 1);
  };
  const counterDecreaseHandler = () => {
    if (increaseValue > 0) {
      setIncreaseValue(increaseValue - 1);
    }
  };
  useEffect(() => {
    let data;
    if (count === NaN) {
      localStorage.setItem("mine-milk-count", 3149);
    }
    if (count !== value && count <= value) {
      data = setInterval(() => setCount(count + randomNumber(1, 5)), 2000);
      localStorage.setItem("mine-milk-count", count);
      return () => clearInterval(data);
    } else {
      setCount(3149);
      localStorage.setItem("mine-milk-count", 3149);
    }
  }, [count]);

  function randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <div className="position-relative">
      <img
        className="position-absolute end-0 top-30 blink_img w-25"
        src={LayerImg}
        alt="LayerImg"
      />
      <div className="container py-5" id="mint">
        <div className="row py-5 justify-content-center">
          <div
            className="col-lg-7 order-lg-1 order-2 mt-lg-0 mt-5 position-relative z-index-1"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <h1 className="main-heading text-lg-start text-center pb-lg-4 pb-3">
              Mint NFT
            </h1>
            <div className="row  py-3 justify-content-center">
              <div className="col-lg-8 col-xxl-6 text-center ">
                <h1 className="main-heading text-white ">
                  {count}/{value} minted
                </h1>
                {/* <p className="medium-text Questrial ">of 3000</p> */}
              </div>
              <div className="col-3 d-none d-lg-block"></div>
            </div>
            <div className="row py-3">
              <div className="col-6">
                <h1 className="font-md text-white text-lg-start text-center">
                  0.1 ETH
                </h1>
                <p className="medium-text Questrial text-lg-start text-center">
                  per NFT
                </p>
              </div>
              <div className="col-6">
                <h1 className="font-md text-white text-lg-start text-center">
                  Max 3 NFTs
                </h1>
                <p className="medium-text Questrial text-lg-start text-center">
                  per transaction
                </p>
              </div>
            </div>
            <div className="row justify-content-lg-start  justify-content-center  mt-4">
              <div className="col-auto  z-index-1 position-relative">
                <img
                  className="cursor-pointer"
                  onClick={() => counterDecreaseHandler()}
                  src={MinusImg}
                  alt="MinusImg"
                />
              </div>
              <div className="col-sm-6 col-5">
                <div className="input-bg py-3 w-100 text-center font-md medium-text Dm-Sans">
                  {increaseValue}
                </div>
              </div>
              <div className="col-auto">
                <img
                  className="cursor-pointer"
                  onClick={() => counterHandler()}
                  src={PlusImg}
                  alt="PlusImg"
                />
              </div>
            </div>
            <div className="text-lg-start text-center">
              <button className="coming-soon px-sm-4 px-3 py-2 Questrial mt-sm-5 mt-3">
                <span className="d-inline mint">MINT NOW</span>
                <span className="d-none soon"> COMING SOON</span>
              </button>
            </div>
          </div>
          <div
            className="col-lg-5 col-7 order-lg-2 order-1"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <img className="w-100 nft-gif" src={NftGif} alt="" />
          </div>
        </div>
      </div>
      <img
        className="position-absolute start-0 bottom-20 blink_img w-25"
        src={LayerImg2}
        alt=""
      />
    </div>
  );
};

export default MintNft;
