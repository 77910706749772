import React from "react";
import RoadmapImg1 from "../assets/img/roadmap/roadmapfirstimg1.png";
import RoadmapImg2 from "../assets/img/roadmap/roadmapimg2.png";
import RoadmapImg from "../assets/img/roadmap/roadmapimage.png";
import LineImg from "../assets/img/roadmap/lineimg.png";
import LayerImg from "../assets/img/about/aboutlayer1.png";
import LayerImg2 from "../assets/img/about/aboutlayer2.png";
import AboutBottleImg1 from "../assets/svg/common/bottle1.svg";
import AboutBottleImg2 from "../assets/svg/common/bottle2.svg";
import CartoonImg from "../assets/img/roadmap/cartoon.png";
const Roadmap = () => {
  return (
    <div className="position-relative" id="roadmap">
      <img className="w-100 " src={RoadmapImg1} alt="" />
      <img
        className="position-absolute top-9 d-none d-sm-block start-0 moving_animation_img"
        src={AboutBottleImg1}
        alt=""
      />
      <img
        className="position-absolute top-60 end-0 moving_animation_img"
        src={AboutBottleImg2}
        alt=""
      />
      <img
        className="position-absolute bottom-0 start-0 blink_img d-md-block d-none"
        src={LayerImg2}
        alt=""
      />
      <section className="py-5 my-sm-5" id="roadmap">
        <div className="container position-relative d-none d-lg-block">
          <div className="position-absolute w-100 d-flex justify-content-center h-100">
            <div>
              <img
                className="w-50 position-sticky top-60 z-index-1 d-block mx-auto"
                src={CartoonImg}
                alt=""
              />
              {/* <img
                className="width-100 position-sticky top-20 z-index-1"
                src={RoadmapImg}
                alt=""
              /> */}
            </div>
            <img
              className="h-100 position-absolute top-20"
              src={LineImg}
              alt=""
            />
          </div>
          {/* <img
            className="position-absolute roadmap_position"
            src={RoadmapLineImg}
            alt=""
          /> */}
          <div className="row justify-content-between">
            <div
              className="col-5 col-xl-4 text-end"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-1</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                All About Community
              </p>
              <p className="Questrial white roadmap_para ">
                Before our official launch date,
                <span className="d-block">community polls will be run to</span>
                determine our next step in building a
                <span className="d-block">strong community.</span>
                The cows will decide who we donate to,
                <span className="d-block">
                  what we envision for the second launch
                </span>
                and also how to give back and reward
                <span className="d-block"> our loyal owners</span>
              </p>
            </div>
            <div
              className="col-5 col-xl-4 mt-5 pt-5"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-2</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                A Good Cause
              </p>
              <p className="Questrial white roadmap_para ">
                The big picture of our collection is to generate steady funds
                into small charities that align with our community’s vision.
                Each charity will be carefully selected and vetted by our
                community and every owner gets 5 votes. After each week, a
                percentage of the fund will be released in proportion based on
                the voting by our cows. Each charity draw result will be
                released on this website and our official Discord channel so
                stay tuned!
              </p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div
              className="col-5 col-xl-4 text-end"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-3</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                Rug Pull?
              </p>
              <p className="Questrial white roadmap_para ">
                We intend to continue to maintain and
                <span className="d-block">
                  generate value for our owners who
                </span>
                have given their trust, support and faith
                <span className="d-block"> to our team from day 1.</span>
                Our decisions on how to continue to
                <span className="d-block">
                  engage the community, drive up floor
                </span>
                prices and generate hype will ONLY
                <span className="d-block"></span>
                come from decisions made by our cows
                <span className="d-block"> in our weekly polls!</span>
              </p>
            </div>
            <div
              className="col-5 col-xl-4 mt-5 pt-5"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-4</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                NFT Vault
              </p>
              <p className="Questrial white roadmap_para ">
                We love trading, collecting and investing in
                <span className="d-block"> NFTs too.</span>
                <span className="d-block">
                  Thats why we are dedicating 50% of our
                </span>
                <span className="d-block">
                  generated funds from our mint into a NFT
                </span>
                <span className="d-block">
                  vault where our community will decide what
                </span>
                <span className="d-block">
                  to buy and HODL. Every 14th of the month,
                </span>
                <span className="d-block">
                  we will release a random NFT from our vault
                </span>
                <span className="d-block">
                  to the community for sale at exactly the price
                </span>
                <span className="d-block">
                  we bought it for, all you need to do is to
                </span>
                <span className="d-block">
                  produce proof that you were part of the first
                </span>
                launch!
              </p>
            </div>
          </div>
          <div className="row justify-content-between">
            <div
              className="col-5 col-xl-4 text-end"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-5</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                Merchandise..?
              </p>
              <p className="Questrial font-md white mb-0 roadmap_para">
                Staking..?
              </p>
              <p className="Questrial white roadmap_para ">
                At this point in our journey, we are
                <span className="d-block">
                  not going to promise you things we
                </span>
                cannot deliver. The community
                <span className="d-block"> decisions will steer us.</span>
                If the cows want merchandise,
                <span className="d-block">you got it.</span>
                If the cows want a cryptocurrency,
                <span className="d-block"> you have it.</span>
              </p>
            </div>
            <div
              className="col-5 col-xl-4 mt-5 pt-5"
              data-aos="fade-zoom-in"
              data-aos-duration="2000"
            >
              <h5 className="font-xl white">PHASE-6</h5>
              <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                Second Launch
              </p>
              <p className="Questrial white roadmap_para ">
                Sorry Cows, this part is set in stone.
                <span className="d-block">
                  Our main goal will be focusing on a
                </span>
                <span className="d-block">
                  second launch, where all our NFTs will be
                </span>
                <span className="d-block">
                  animated (just like our legendaries!)
                </span>
                <span className="d-block">
                  Only owners of our first launch will be able
                </span>
                <span className="d-block">
                  to mint their very own animated NFT.
                </span>
                <span className="d-block">
                  The size of our second collection will solely
                </span>
                <span className="d-block">
                  be determined on how many owners
                </span>
                <span className="d-block"> decide to mint again!</span>
              </p>
            </div>
          </div>
        </div>
        <div className="container d-lg-none">
          <div className="row">
            <div className="col-3 col-md-4 d-flex justify-content-center position-relative">
              <div>
                <img
                  className=" position-sticky top-60 z-index-1 width-100"
                  src={RoadmapImg}
                  alt=""
                />
              </div>
              <img
                className="width-10 position-absolute top-0 h-100"
                src={LineImg}
                alt=""
              />
            </div>
            <div className="col-9 col-md-8">
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-1</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  All About Community
                </p>
                <p className="Questrial white roadmap_para ">
                  Before our official launch date,
                  <span className="d-block">
                    community polls will be run to
                  </span>
                  determine our next step in building a
                  <span className="d-block">strong community.</span>
                  The cows will decide who we donate to,
                  <span className="d-block">
                    what we envision for the second launch
                  </span>
                  and also how to give back and reward
                  <span className="d-block"> our loyal owners</span>
                </p>
              </div>
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-2</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  A Good Cause
                </p>
                <p className="Questrial white roadmap_para ">
                  The big picture of our collection is to generate steady funds
                  into small charities that align with our community’s vision.
                  Each charity will be carefully selected and vetted by our
                  community and every owner gets 5 votes. After each week, a
                  percentage of the fund will be released in proportion based on
                  the voting by our cows. Each charity draw result will be
                  released on this website and our official Discord channel so
                  stay tuned!
                </p>
              </div>
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-3</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  Rug Pull?
                </p>
                <p className="Questrial white roadmap_para ">
                  We intend to continue to maintain and
                  <span className="d-block">
                    generate value for our owners who
                  </span>
                  have given their trust, support and faith
                  <span className="d-block"> to our team from day 1.</span>
                  Our decisions on how to continue to
                  <span className="d-block">
                    engage the community, drive up floor
                  </span>
                  prices and generate hype will ONLY
                  <span className="d-block"></span> come from decisions made by
                  our cows<span className="d-block"> in our weekly polls!</span>
                </p>
              </div>
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-4</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  NFT Vault
                </p>
                <p className="Questrial white roadmap_para ">
                  We love trading, collecting and investing in
                  <span className="d-block"> NFTs too.</span>
                  <span className="d-block">
                    Thats why we are dedicating 50% of our
                  </span>
                  <span className="d-block">
                    generated funds from our mint into a NFT
                  </span>
                  <span className="d-block">
                    vault where our community will decide what
                  </span>
                  <span className="d-block">
                    to buy and HODL. Every 14th of the month,
                  </span>
                  <span className="d-block">
                    we will release a random NFT from our vault
                  </span>
                  <span className="d-block">
                    to the community for sale at exactly the price
                  </span>
                  <span className="d-block">
                    we bought it for, all you need to do is to
                  </span>
                  <span className="d-block">
                    produce proof that you were part of the first
                  </span>
                  launch!
                </p>
              </div>
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-5</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  Merchandise..?
                </p>
                <p className="Questrial font-md white mb-0 roadmap_para">
                  Staking..?
                </p>
                <p className="Questrial white roadmap_para ">
                  At this point in our journey, we are
                  <span className="d-block">
                    not going to promise you things we
                  </span>
                  cannot deliver. The community
                  <span className="d-block"> decisions will steer us.</span>
                  If the cows want merchandise,
                  <span className="d-block">you got it.</span>
                  If the cows want a cryptocurrency,
                  <span className="d-block"> you have it.</span>
                </p>
              </div>
              <div
                className="mt-5"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h5 className="font-xl white">PHASE-6</h5>
                <p className="Questrial font-md white mb-0 mt-3 roadmap_para">
                  Second Launch
                </p>
                <p className="Questrial white roadmap_para ">
                  Sorry Cows, this part is set in stone.
                  <span className="d-block">
                    Our main goal will be focusing on a
                  </span>
                  <span className="d-block">
                    second launch, where all our NFTs will be
                  </span>
                  <span className="d-block">
                    animated (just like our legendaries!)
                  </span>
                  <span className="d-block">
                    Only owners of our first launch will be able
                  </span>
                  <span className="d-block">
                    to mint their very own animated NFT.
                  </span>
                  <span className="d-block">
                    The size of our second collection will solely
                  </span>
                  <span className="d-block">
                    be determined on how many owners
                  </span>
                  <span className="d-block"> decide to mint again!</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img
        className="position-absolute end-0 top-20 blink_img "
        src={LayerImg}
        alt=""
      />
      <img className="w-100" src={RoadmapImg2} alt="" />
    </div>
  );
};

export default Roadmap;
