import React from "react";
import Header from "./common/Header";
import HeroImg from "../assets/img/hero/hero.png";
import LowerHeroImg from "../assets/img/hero/heroimg.png";
const Hero = () => {
  return (
    <div id="home">
      <Header />
      <div className="d-flex flex-column  hero-section position-relative">
        <div className="container d-flex flex-column flex-grow-1  justify-content-center">
          <div className="row position-relative z-index-1 justify-content-center">
            <div className="col-lg-5 col-8 order-lg-1 order-2">
              <img className="w-100 pb-lg-5" src={HeroImg} alt="" />
            </div>
            <div
              className="col-lg-7 my-auto order-lg-2 order-1"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <h1 className="font-2xxl sky-blue text-lg-start text-center">
                Mean Milk
              </h1>
              <p className="medium-text Questrial font-xmd text-lg-start text-center">
                A 5,000 strong army of NFTs coming soon to a grocery store near
                you
              </p>
              <p className="medium-text Questrial mb-0 text-lg-start text-center">
                The Freshest & Meanest
              </p>
              <p className="medium-text Questrial text-lg-start text-center">
                - Only from The Milk Man
              </p>
              <div className="d-flex flex-sm-row flex-column mb-5 pb-2 justify-content-lg-start align-items-sm-start  align-items-center  justify-content-center mt-lg-0 mt-4">
                <button className="mint-now px-sm-4 px-3  Questrial">
                  <span className="d-inline mint-btn">MINT NOW</span>
                  <span className="d-none comming-soon"> COMING SOON</span>
                </button>

                <button className="explore-btn  px-sm-4 px-3 py-2 Questrial ms-sm-3 mt-sm-0 mt-3 ">
                  Explore More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute bottom-0 w-100">
          <img className="w-100" src={LowerHeroImg} alt="" />
        </div>
      </div>

      <section className="margin-top-25">
        <div class="scroll ">
          <div class="m-scroll pt-1">
            <span className="font-lg black fw-bold line_height_1">
              FRESH FROM THE MILK MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK
              MAN.&nbsp;&nbsp;&nbsp;FRESH FROM THE MILK MAN.
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
