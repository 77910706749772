import React from "react";
import { useScreenFixedProvider } from "../context/ScreenProvider";
import LogoImg from "../../assets/img/header/logo.svg";
import LogoImg2 from "../../assets/img/header/logo.png";
import { DiscordIcon, InstagramIcon, ShipIcon, TwitterIcon } from "./Icons";
const Header = () => {
  const { showOverlay, setShowOverlay } = useScreenFixedProvider();

  return (
    <>
      <section className=" navbar-wrapper py-3">
        <div className="container">
          <div className="d-lg-flex justify-content-between align-items-center w-100 d-none">
            <img src={LogoImg} alt="" />
            <div>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#home">HOME</a>
              </li>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#about">ABOUT</a>
              </li>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#team">TEAM</a>
              </li>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#roadmap">ROADMAP</a>
              </li>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#faq">FAQS</a>
              </li>
              <li className="mx-3 nav-list-items  d-inline-block cursor-pointer">
                <a href="#mint">MINT</a>
              </li>
            </div>
            <div>
              <a
                href="https://www.twitter.com/meanmilknft"
                rel="noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
              <a
                href=" https://www.discord.gg/meanmilk"
                rel="noreferrer"
                target="_blank"
              >
                <DiscordIcon />
              </a>
              <a
                href="https://www.instagram.com/meanmilk.io"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://opensea.io/milkfridge"
                rel="noreferrer"
                target="_blank"
              >
                <ShipIcon />
              </a>
            </div>
          </div>

          {/* OVERLAY HERE  THIS CODE RUN BEFORE 992PX*/}
          <div className="d-lg-none d-flex">
            <div
              className={`${showOverlay ? "overlay-active" : ""} overlay-nav`}
            >
              <div className="nav-bg-light h-screen d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column text-center ">
                  <ul className="p-0">
                    <li className=" transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#home"
                          className="nav-link text-white  text-decoration-none cursor-pointer"
                        >
                          Home
                        </a>
                      </span>
                    </li>
                    <li className=" transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#about"
                          className="nav-link text-white  text-decoration-none cursor-pointer"
                        >
                          ABOUT
                        </a>
                      </span>
                    </li>
                    <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#team"
                          className="nav-link text-white text-decoration-none"
                        >
                          TEAM
                        </a>
                      </span>
                    </li>
                    <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#roadmap"
                          className="nav-link text-white  text-decoration-none"
                        >
                          Roadmap
                        </a>
                      </span>
                    </li>
                    <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#faq"
                          className="nav-link text-white  text-decoration-none"
                        >
                          FAQs
                        </a>
                      </span>
                    </li>
                    <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <a
                          href="/#mint"
                          className="nav-link text-white  text-decoration-none"
                        >
                          MINT
                        </a>
                      </span>
                    </li>
                    <li className="nav-item btn-img mt-3 mt-lg-1  transition-0_3sEase overflow-hidden">
                      <span
                        className="py-2 py-sm-4 d-inline-block cursor-pointer"
                        onClick={() => setShowOverlay(false)}
                      >
                        <div>
                          <a
                            href="https://twitter.com/home"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <TwitterIcon />
                          </a>
                          <a
                            href=" https://discord.com/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <DiscordIcon />
                          </a>
                          <a
                            href="https://www.instagram.com/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <InstagramIcon />
                          </a>
                          <a
                            href="https://opensea.io/login"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <ShipIcon />
                          </a>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <nav className="navbar d-lg-none d-flex h-86 navbar-expand-lg navbar-light p-3 w-100">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <img className="width-100" src={LogoImg2} alt="" />

              <div
                className={`${
                  showOverlay ? "animate" : ""
                } position-relative navbarwrapper  hamburger-icon d-flex flex-column d-lg-none`}
                onClick={() => setShowOverlay(!showOverlay)}
              >
                <span className="first d-inline-block"></span>
                <span className="second d-inline-block"></span>
                <span className="third d-inline-block"></span>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
