import React from "react";
import { nftSliderData, mftSliderDataSecond } from "./common/content";

const NftSlider = () => {
  return (
    <section className="overflow-x-hidden">
      <div data-aos="fade-up" data-aos-duration="2000">
        <div className="slider-straight">
          <div className="slide-track-straight">
            {mftSliderDataSecond &&
              mftSliderDataSecond.map((value, index) => (
                <div className="slide mx-3" key={index}>
                  <img src={value.img} className="slider-img" alt="" />
                </div>
              ))}
          </div>
        </div>
        <div className="my-3">
          <div className="slider-straight-second">
            <div className="slide-track-straight-second justify-content-end">
              {nftSliderData &&
                nftSliderData.map((value, index) => (
                  <div className="slide mx-3" key={index}>
                    <img src={value.img} className="slider-img" alt="" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NftSlider;
