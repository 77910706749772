import React from "react";
import FaqImg from "../assets/img/faq/faqimg.png";
import LayerImg from "../assets/img/about/aboutlayer1.png";
import LayerImg2 from "../assets/img/about/aboutlayer2.png";
const Faq = () => {
  return (
    <section className="py-5 position-relative" id="faq">
      <div className="position-absolute end-0 footer_cartoon d-none d-lg-block">
        <img className="moving_animation_img " src={FaqImg} alt="" />
      </div>
      <img
        className="position-absolute top-50 end-0 blink_img FooterLayerImg"
        src={LayerImg}
        alt=""
      />
      <img
        className="position-absolute top-50 start-0 blink_img FooterLayerImg"
        src={LayerImg2}
        alt=""
      />

      <div className="container">
        <div>
          <h3 className="main-heading">FAQs</h3>
        </div>
        <div className="row">
          <div className="col mt-2 mt-sm-5">
            <div className="accordion" id="accordionExample">
              <div
                className="accordion-item"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2 className="accordion-header white font-lg" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Q1. What is the Total Supply of MEAN MILK?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    There will be a total of 5,000 Unique MEAN MILK NFTs
                    available for mint!
                    <br></br>
                    10 of which are legendary; all traits of the legendary NFTs
                    are 1 of 1 rarity.
                    <br></br>
                    The rest of the collection consists of hundreds of unique
                    traits.
                    <div className="accordian-border-bottom mt-5"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2 className="accordion-header white font-lg" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Q2. What is the Mint Price?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    MEAN MILK NFTs will mint at
                    <br></br>
                    0.08Ξ for Pre Sale
                    <br></br>
                    0.1Ξ for Public Sale
                    <br></br>
                    Well, you know what to do then!
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2
                  className="accordion-header white font-lg"
                  id="headingThree"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Q3. When will MEAN MILK Launch?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    TBC.
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2 className="accordion-header white font-lg" id="headingfour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    Q4. How many can I mint?
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    The Pre Sale allows 1 NFTs minted per wallet; eligible
                    members are those given Whitelisted roles.
                    <br></br>
                    The Public Sale allows 3 NFTs minted per wallet; don't be
                    too greedy!
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2 className="accordion-header white font-lg" id="headingfive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    Q5. What smart contract is MEAN MILK build on?
                  </button>
                </h2>
                <div
                  id="collapsefive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    We use a Gas Efficient ERC-721A smart contract.
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2 className="accordion-header white font-lg" id="headingsix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsesix"
                    aria-expanded="false"
                    aria-controls="collapsesix"
                  >
                    Q6. Do I own the IP of the minted NFT?
                  </button>
                </h2>
                <div
                  id="collapsesix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingsix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    Yes. Holding your MEAN MILK NFT comes with the full
                    commercial art rights to create and distribute derivatives.
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-item mt-2 mt-sm-3"
                data-aos="fade-zoom-in"
                data-aos-duration="2000"
              >
                <h2
                  className="accordion-header white font-lg"
                  id="headingseven"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseseven"
                    aria-expanded="false"
                    aria-controls="collapseseven"
                  >
                    Q7. Who can I contact for any matters?
                  </button>
                </h2>
                <div
                  id="collapseseven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingseven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body font-sm Questrial white">
                    For any matters, you may open a ticket on our Discord or
                    send an email to hello@meanmilk.io.
                    <div className="accordian-border-bottom mt-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
