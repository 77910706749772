import { useState, useEffect } from "react";
import "../src/assets/style/common.css";
import Home from "./view/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import LogoImg from "./assets/img/header/logo.png";
import Snowfall from "react-snowfall";
import { UpArrowIcon } from "./component/common/Icons";
import { useScreenFixedProvider } from "./component/context/ScreenProvider";

function App() {
  const [loading, setLoading] = useState(false);
  const { showOverlay, setShowOverlay } = useScreenFixedProvider();
  const [showButton, setShowButton] = useState(false);

  AOS.init({ once: true });
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {loading ? (
        <div className={`${showOverlay ? "overflow-hidden-100vh " : "h-100"}`}>
          <Snowfall
            snowflakeCount={150}
            snowStick={true}
            freezeOnBlur={true}
            style={{
              height: "100vh",
              zIndex: 15,
            }}
          />

          <Home />
        </div>
      ) : (
        <div className="h-100vh d-flex flex-column justify-content-center align-items-center">
          <img className="loader-img" src={LogoImg} alt="img" />
        </div>
      )}
      <>
        {showButton ? (
          <div
            className="topscroll d-flex justify-content-center align-items-center cursor-pointer"
            onClick={() => scrollToTop()}
          >
            <UpArrowIcon />
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
}

export default App;
