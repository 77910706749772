import React from "react";
import { TeamSliderData } from "./common/content";
import Slider from "react-slick";
import TeamLayer1 from "../assets/img/about/aboutlayer2.png";
import TeamLayer2 from "../assets/img/about/aboutlayer1.png";

const Team = () => {
  var settings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div className="position-relative py-5 pt-lg-5 pt-3 pb-sm-5 pb-0" id="team">
      <img
        className="position-absolute top-30 start-0 blink_img w-25 z-index_2"
        src={TeamLayer1}
        alt=""
      />
      <div className="container py-5 pt-sm-5 pt-2">
        <h1
          className="main-heading  text-center pb-lg-4 pb-3"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          team
        </h1>
        <div data-aos="fade-up" data-aos-duration="2000">
          <Slider {...settings} className="team-slider py-sm-5 my-sm-5">
            {TeamSliderData &&
              TeamSliderData.map((value, index) => {
                return (
                  <div className="team_card" key={index}>
                    <img
                      className="w-75 mx-auto d-block px-2"
                      src={value.teamimg}
                      alt=""
                    />
                    <h1 className="text-center sky-blue pt-4 font-lg">
                      {value.heading}
                    </h1>
                    <p className="text-center medium-text Questrial uppercase">
                      {value.text}
                    </p>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
      <img
        className="position-absolute bottom-20 d-lg-block d-none end-0 blink_img w-25 z-index_2"
        src={TeamLayer2}
        alt=""
      />
    </div>
  );
};

export default Team;
