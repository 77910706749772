import React from "react";
import { DiscordIcon, InstagramIcon, ShipIcon, TwitterIcon } from "./Icons";

const Footer = () => {
  return (
    <section className="pb-5 py-sm-5 mt-sm-5">
      <div className="container">
        <div className="row">
          <div className="col" data-aos="zoom-in" data-aos-duration="2000">
            <div className="text-center">
              <h3 className="main-heading">MEAN MILK</h3>
              <ul className="d-flex flex-sm-row flex-column list-unstyled align-items-center justify-content-center mt-4">
                <a className="text-decoration-none" href="/home">
                  <li className="white footer_list">HOME</li>
                </a>
                <a className="text-decoration-none" href="#about">
                  <li className="white ms-2 ms-sm-4 footer_list">ABOUT</li>
                </a>
                <a className="text-decoration-none" href="#team">
                  <li className="white ms-2 ms-sm-4 footer_list">TEAM</li>
                </a>
                <a className="text-decoration-none" href="#roadmap">
                  <li className="white ms-2 ms-sm-4 footer_list">ROADMAP</li>
                </a>
                <a className="text-decoration-none" href="#faq">
                  <li className="white ms-2 ms-sm-4 footer_list">FAQ</li>
                </a>
                <a className="text-decoration-none" href="#mint">
                  <li className="white ms-2 ms-sm-4 footer_list">MINT</li>
                </a>
              </ul>
              <div className="mt-4">
                <a
                  rel="noreferrer"
                  href="https://www.twitter.com/meanmilknft"
                  target="_blank"
                >
                  <TwitterIcon />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.discord.gg/meanmilk"
                  target="_blank"
                >
                  <DiscordIcon />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/meanmilk.io"
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
                <a
                  rel="noreferrer"
                  href="https://opensea.io/milkfridge"
                  target="_blank"
                >
                  <ShipIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
