import React from "react";

const MovingBanner = () => {
  return (
    // <section className="bg-sky-blue  pt-2 mt-5">
    //   <marquee behavior="slide " scrollamount="12">
    //     <p className="font-lg black mb-0">
    //       THE FRESHEST & MEANEST. &nbsp;&nbsp; &nbsp; THE FRESHEST & MEANEST.
    //       &nbsp;&nbsp; &nbsp;THE FRESHEST & MEANEST. &nbsp;&nbsp; &nbsp;THE
    //       FRESHEST & MEANEST. &nbsp;&nbsp; &nbsp;THE FRESHEST & MEANEST.
    //       &nbsp;&nbsp; &nbsp;THE FRESHEST & MEANEST.
    //     </p>
    //   </marquee>
    // </section>
    <div class="scroll my-5">
      <div class="m-scroll pt-1 ">
        <span className="font-lg black fw-bold line_height_1">
          THE FRESHEST & MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST &
          MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST & MEANEST.&nbsp;&nbsp;&nbsp;
          THE FRESHEST & MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST &
          MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST & MEANEST.&nbsp;&nbsp;&nbsp;
          THE FRESHEST & MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST &
          MEANEST.&nbsp;&nbsp;&nbsp;THE FRESHEST & MEANEST.
        </span>
      </div>
    </div>
    // <div className="marquee bg-sky-blue">
    //   <div className="track">
    //     <div className="content font-sm">
    //       THE FRESHEST & MEANEST. THE FRESHEST & MEANEST.THE FRESHEST & MEANEST.
    //       THE FRESHEST & MEANEST.THE FRESHEST & MEANEST.THE FRESHEST & MEANEST.
    //     </div>
    //   </div>
    // </div>
  );
};

export default MovingBanner;
