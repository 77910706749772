import React from "react";
import Faq from "../component/Faq";
import Hero from "../component/Hero";
import MintNft from "../component/MintNft";
import NftSlider from "../component/NftSlider";
import Team from "../component/Team";
import Footer from "../component/common/Footer";
import About from "../component/About";
import Roadmap from "../component/Roadmap";
import MovingBanner from "../component/MovingBanner";

const Home = () => {
  return (
    <>
      <Hero />
      <MintNft />
      <NftSlider />
      <About />
      <MovingBanner />
      <Team />
      <Roadmap />
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
