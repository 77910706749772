import NftSliderImg1 from "../../assets/img/nftslider/NFT 1.gif";
import NftSliderImg2 from "../../assets/img/nftslider/NFT 2.gif";
import NftSliderImg3 from "../../assets/img/nftslider/NFT 4.gif";
import NftSliderImg4 from "../../assets/img/nftslider/NFT 5.gif";
import NftSliderImg5 from "../../assets/img/nftslider/NFT 7.gif";
import NftSliderImg6 from "../../assets/img/nftslider/NFT 8.gif";
import NftSliderImg7 from "../../assets/img/nftslider/NFT 10F.gif";
import NftSliderImg8 from "../../assets/img/nftslider/NFT 11F.gif";
import NftSliderImg9 from "../../assets/img/nftslider/NFT 12F.gif";
import NftSliderImg10 from "../../assets/img/nftslider/NFT 13F.gif";
import NftSliderImg11 from "../../assets/img/nftslider/NFT 14F.gif";
import NftSliderImg12 from "../../assets/img/nftslider/NFT 16F.gif";
// =================================================================== team===================================================
import TeamImg1 from "../../assets/img/team/team1.png";
import TeamImg2 from "../../assets/img/team/team2.png";
import TeamImg3 from "../../assets/img/team/team3.png";

export const nftSliderData = [
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
  {
    img: NftSliderImg1,
  },
  {
    img: NftSliderImg2,
  },
  {
    img: NftSliderImg3,
  },
  {
    img: NftSliderImg4,
  },
  {
    img: NftSliderImg5,
  },
  {
    img: NftSliderImg6,
  },
];
export const mftSliderDataSecond = [
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
  {
    img: NftSliderImg9,
  },
  {
    img: NftSliderImg8,
  },
  {
    img: NftSliderImg7,
  },
  {
    img: NftSliderImg12,
  },
  {
    img: NftSliderImg11,
  },
  {
    img: NftSliderImg10,
  },
];
export const TeamSliderData = [
  {
    teamimg: TeamImg1,
    heading: "Choco’ Chip",
    text: "designer",
  },
  {
    teamimg: TeamImg2,
    heading: "The Milk Man",
    text: "founder",
  },
  {
    teamimg: TeamImg3,
    heading: "Ser Straw",
    text: "Developer ",
  },
  {
    teamimg: TeamImg1,
    heading: "Choco’ Chip",
    text: "DESIGNER",
  },
  {
    teamimg: TeamImg2,
    heading: "The Milk Man",
    text: "FOUNDER",
  },
  {
    teamimg: TeamImg3,
    heading: "Ser Straw",
    text: "DEVELOPER",
  },
];
