import React from "react";
import {
  DiscordIcon,
  InstagramIcon,
  ShipIcon,
  TwitterIcon,
} from "./common/Icons";
import AboutBottleImg1 from "../assets/svg/common/bottle1.svg";
import AboutBottleImg2 from "../assets/svg/common/bottle2.svg";
import AboutImg from "../assets/img/about/about.gif";
import Slider from "react-slick";

const About = () => {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
  };
  return (
    <section className="about_Section position-relative py-5" id="about">
      <div className="container">
        <div className="row pt-3 pt-lg-5 z-index-1 position-relative justify-content-lg-between justify-content-center">
          <div
            className="col-lg-5  col-sm-7 col-9"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <Slider {...settings} className="about-slider">
              <div className="px-lg-3 px-2">
                <img className="w-100 about-img " src={AboutImg} alt="" />
              </div>
              <div className="px-lg-3 px-2">
                <img className="w-100 about-img " src={AboutImg} alt="" />
              </div>
            </Slider>
          </div>
          <div
            className="col-lg-6 col-sm-10 my-auto pt-lg-0 pt-5"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <h2 className="main-heading text-lg-start text-center">about us</h2>
            <p className="text-white font-normal Questrial m-0 text-lg-start text-center">
              Mean Milk is a collection of 5,000 NFTs with hundreds of unique
              traits; you never know what you’re going to get!
            </p>
            <p className="text-white font-normal Questrial m-0 text-lg-start text-center">
              Designed to perfection, each of our NFTs portray a different vibe
              & sense of pride to their very own lucky owner.
            </p>
            <p className="text-white font-normal Questrial text-lg-start text-center">
              A main collection of 4990 with varying rarities of traits and an
              exclusive 10 legendaries with 1 of 1 qualities.
            </p>
            <div className="mt-3 text-lg-start text-center">
              <a
                href="https://www.twitter.com/meanmilknft"
                rel="noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.discord.gg/meanmilk"
                rel="noreferrer"
                target="_blank"
              >
                <DiscordIcon />
              </a>
              <a
                href="https://www.instagram.com/meanmilk.io"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://opensea.io/milkfridge"
                rel="noreferrer"
                target="_blank"
              >
                <ShipIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute top-0 d-flex  ">
        <img className="moving_animation_img" src={AboutBottleImg1} alt="" />
      </div>
      <div className="position-absolute bottom-0 d-flex   end-0 pt-sm-0 ">
        <img
          className=" mt-5 moving_animation_img"
          src={AboutBottleImg2}
          alt=""
        />
      </div>
    </section>
  );
};

export default About;
